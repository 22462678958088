import { initContainers } from "../util/init"
import { updateLinkState } from "../util/link_state"
import { addHandler } from "../util/scroll_resize.js"
import { isMobileSite } from "./constants"
import navigation from "./navigation"
import docReady from "doc-ready"
import { pats } from "./patterns"
import { VideoPlayer } from "../../videoplayer/videoplayer"
import paper from "paper"

const OVERLAY_SPEED = 1750

class Overlay {
  constructor(el, wrapEl) {
    const that = this
    this.el = el.parentNode
    this.innerEl = el
    this.videoWrap = wrapEl.querySelector(".video-player").cloneNode(true)
    this.textEl = document.createElement("div")
    this.textEl.classList.add("text-wrap")
    wrapEl.querySelectorAll(".text").forEach(textEl => {
      that.textEl.appendChild(textEl.cloneNode(true))
    })
    this.bkgEl = document.createElement("div")
    this.bkgEl.classList.add("bkg")
    this.closeEl = this.el.querySelector(".overlay-close")
    this.visible = false

    this.innerEl.appendChild(this.videoWrap)
    this.innerEl.appendChild(this.textEl)
    this.innerEl.appendChild(this.bkgEl)

    this.setup()
    this.load()
  }

  setup() {
    const that = this

    this.closeEl.addEventListener("click", () => {
      this.player.pause()

      setTimeout(() => {
        that.destroy()
      }, that.unload())
    })

    this.player = new VideoPlayer(this.videoWrap)
    this.player.play()
  }

  clip(visible) {
    const img = this.videoWrap
    let w = img.offsetWidth
    img.style.clip = `rect(0px 100vw ${img.offsetHeight}px ${
      visible ? "0vw" : "100vw"
    })`
  }

  load() {
    const html = document.documentElement
    html.classList.add("state-overlay-visible")

    this.clip(false)

    setTimeout(() => {
      html.classList.add("state-overlay-transition-in")

      setTimeout(() => {
        this.visible = true
        this.clip(true)

        setTimeout(() => {
          html.classList.add("state-overlay-logo-visible")
        }, (OVERLAY_SPEED / 46) * 1)
      }, (OVERLAY_SPEED / 46) * 8)

      setTimeout(() => {
        html.classList.add("state-overlay-ready")
        html.classList.remove("state-overlay-transition-in")
      }, OVERLAY_SPEED)
    }, 250)
  }

  unload() {
    const html = document.documentElement

    this.visible = false
    this.clip(false)

    setTimeout(() => {
      html.classList.add("state-overlay-transition")
      html.classList.remove(
        "state-overlay-ready",
        "state-overlay-transition-in"
      )

      setTimeout(() => {
        html.classList.remove("state-overlay-logo-visible")
      }, (OVERLAY_SPEED / 46) * 33)
    }, (OVERLAY_SPEED / 46) * 8)

    return (OVERLAY_SPEED / 46) * 8 + 1750
  }

  resize(...args) {
    this.clip(this.visible)
  }

  destroy() {
    document.documentElement.classList.remove(
      "state-overlay-visible",
      "state-overlay-transition-in",
      "state-overlay-ready",
      "state-overlay-transition"
    )
    while (this.innerEl.lastChild) {
      this.innerEl.removeChild(this.innerEl.lastChild)
    }
  }
}

function initMenu() {
  const html = document.documentElement
  const overlayEl = html.querySelector("#overlay .overlay-inner")
  const burgerEl = html.querySelector("button.hamburger")
  let controller = { obj: {}, resize: () => {} }
  const categoryWrap = html.querySelector("#content .page-wrap")
  let isHome = document.body.classList.contains("home")
  let menuVisible = false
  let overlayObj

  const toggle = (visible = null) => {
    if (visible === null) {
      menuVisible = !menuVisible
    } else {
      menuVisible = visible
    }
    document.body.classList[menuVisible ? "add" : "remove"](
      "state-menu-visible"
    )
    burgerEl.classList[menuVisible ? "add" : "remove"]("is-active")
    html.classList[menuVisible ? "add" : "remove"]("state-menu-visible")
  }

  burgerEl.addEventListener("click", e => {
    e.preventDefault()
    toggle()
  })

  const updateVideos = elems => {
    elems.forEach(el => {
      const playEl = el.querySelector(".video-player .playpause")

      if (playEl) {
        playEl.addEventListener("click", () => {
          if (overlayObj) overlayObj.destroy()

          overlayObj = new Overlay(
            overlayEl,
            el.querySelector(".video-player-wrap")
          )
        })
      }
    })
  }

  if (!isMobileSite()) {
    let oldSpeed = 0
    controller = pats()

    oldSpeed = controller.obj.getVelocity()

    setTimeout(() => {
      const promise = controller.obj.load(
        parseInt(categoryWrap.getAttribute("data-pattern-id")),
        parseInt(categoryWrap.getAttribute("data-pattern-stop"))
      )
      promise.then(() => {
        controller.obj.locked = false
        document.documentElement.classList.add("state-home-loaded")
      })
    }, isHome ? 1000 : 0)

    updateVideos(html.querySelectorAll(".carousel-item.is-video"))
  }

  return {
    controller: controller.obj,
    toggle: toggle,
    updateVideos: updateVideos,
    resize: (...args) => {
      if (
        !isMobileSite() &&
        !html.classList.contains("state-container-initialising")
      ) {
        controller.resize(...args)

        if (overlayObj) overlayObj.resize(...args)
      }
    },
    scroll: (...args) => {
      // if (
      //   !isMobileSite() &&
      //   !html.classList.contains("state-container-initialising")
      // ) {
      //   controller.scroll(...args)
      // }
    }
  }
}

function initContent(container) {
  const html = document.documentElement

  html.classList.add("state-container-initialising")
  if (container) {
    // initialises newly loaded content
    const current = container.children

    initContainers(current)
  }
  updateLinkState(document.querySelectorAll("#header, #footer, #content"))

  setTimeout(() => {
    html.classList.remove("state-container-initialising")
  }, 40)
}

function setup() {
  if (!isMobileSite()) paper.install(window)

  const menu = initMenu()
  navigation(initContent, menu)

  window.onpopstate = function(event) {
    window.location.reload()
    return false
  }

  addHandler({
    resize: (...args) => {
      menu.resize(...args)
    },
    scroll: (...args) => {
      menu.scroll(...args)
    }
  })
}

docReady(() => {
  setup()
  initContent(document.querySelector("#content"))

  document.body.classList.add("state-initialised")
})
