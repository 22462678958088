import { paper } from "paper"
import { Decimal } from "decimal.js"
import BezierEasing from "bezier-easing"

const linearEasing = BezierEasing(0, 0, 1, 1)
const bezierEasing = BezierEasing(0.42, 0, 0.58, 1)

const TOTAL_COLUMNS = new Decimal(46)

const PATTERNS = [
  [
    [
      [0, 12],
      [5, 12],
      [5, 0],
      [9, 0],
      [9, 24],
      [17, 24],
      [17, 16],
      [21, 16],
      [21, 4],
      [33, 4],
      [33, 12],
      [46, 12],
      [46, 16],
      [45, 16],
      [45, 20],
      [41, 20],
      [41, 16],
      [33, 16],
      [33, 20],
      [21, 20],
      [21, 24],
      [25, 24],
      [25, 28],
      [13, 28],
      [13, 32],
      [9, 32],
      [9, 28],
      [5, 28],
      [5, 16],
      [0, 16],
      [0, 12]
    ],
    [
      [33, 28],
      [45, 28],
      [45, 32],
      [46, 32],
      [46, 36],
      [41, 36],
      [41, 32],
      [37, 32],
      [37, 36],
      [33, 36],
      [33, 28]
    ]
  ],
  [
    [
      [0, 12],
      [9, 12],
      [9, 0],
      [13, 0],
      [13, 12],
      [17, 12],
      [17, 0],
      [21, 0],
      [21, 16],
      [9, 16],
      [9, 24],
      [5, 24],
      [5, 16],
      [0, 16],
      [0, 12]
    ],
    [
      [9, 24],
      [21, 24],
      [21, 20],
      [46, 20],
      [46, 36],
      [21, 36],
      [21, 28],
      [9, 28],
      [9, 24]
    ]
  ],
  [
    [
      [0, 12],
      [9, 12],
      [9, 0],
      [13, 0],
      [13, 12],
      [17, 12],
      [17, 16],
      [9, 16],
      [9, 24],
      [21, 24],
      [21, 20],
      [46, 20],
      [46, 36],
      [21, 36],
      [21, 28],
      [5, 28],
      [5, 16],
      [0, 16]
    ]
  ],
  [
    [
      [0, 20],
      [9, 20],
      [9, 4],
      [13, 4],
      [13, 8],
      [21, 8],
      [21, 20],
      [46, 20],
      [46, 36],
      [21, 36],
      [17, 36],
      [17, 12],
      [13, 12],
      [13, 24],
      [0, 24],
      [0, 20]
    ],
    [[13, 0], [17, 0], [17, 4], [13, 4]]
  ],
  [
    [
      [0, 12],
      [5, 12],
      [5, 0],
      [21, 0],
      [21, 4],
      [9, 4],
      [9, 12],
      [17, 12],
      [17, 4],
      [21, 4],
      [21, 16],
      [5, 16],
      [5, 28],
      [1, 28],
      [1, 16],
      [0, 16],
      [0, 12]
    ],
    [[9, 20], [13, 20], [13, 36], [9, 36], [9, 20]],
    [[21, 20], [46, 20], [46, 36], [21, 36], [21, 20]]
  ],
  [
    [
      [0, 8],
      [17, 8],
      [17, 0],
      [21, 0],
      [21, 20],
      [46, 20],
      [46, 36],
      [17, 36],
      [17, 12],
      [0, 12],
      [0, 8]
    ]
  ],
  [
    [
      [0, 8],
      [17, 8],
      [17, 0],
      [21, 0],
      [21, 20],
      [46, 20],
      [46, 36],
      [21, 36],
      [21, 28],
      [17, 28],
      [17, 36],
      [13, 36],
      [13, 28],
      [9, 28],
      [9, 36],
      [5, 36],
      [5, 24],
      [17, 24],
      [17, 12],
      [0, 12],
      [0, 8]
    ]
  ]
]

const addPattern = (obj, magnitude) => {
  const group = new paper.Group()
  obj.rawPatterns.forEach(pattern => {
    const path = new paper.Path()
    pattern.forEach(p => {
      const point = new Point({
        x: new Decimal(p[0]).times(magnitude).toPrecision(10),
        y: new Decimal(p[1]).times(magnitude).toPrecision(10)
      })
      path.add(point)
    })
    group.addChild(path)
  })
  obj.mainGroup.addChild(group)
}

const resizePattern = (obj, magnitude) => {
  const group = obj.mainGroup.firstChild
  group.removeChildren()

  obj.rawPatterns.forEach(pattern => {
    const path = new paper.Path()
    pattern.forEach(p => {
      const point = new Point({
        x: new Decimal(p[0]).times(magnitude).toPrecision(10),
        y: new Decimal(p[1]).times(magnitude).toPrecision(10)
      })
      path.add(point)
    })
    group.addChild(path)
  })
}

export function pats(patternIndex) {
  const canvasEl = document.getElementById("drain")
  paper.setup(canvasEl)
  paper.view.autoUpdate = false

  let resizeTimer

  const VELOCITY_RATE = new Decimal(0.4)

  const getCanvasPx = px => {
    return new Decimal(px).dividedBy(paper.view.pixelRatio)
  }

  let CANVAS_WIDTH = getCanvasPx(canvasEl.width)
  let CANVAS_HEIGHT = getCanvasPx(canvasEl.height)
  let COLWIDTH = CANVAS_WIDTH.dividedBy(TOTAL_COLUMNS)
  let COLHEIGHT = CANVAS_HEIGHT.dividedBy(36)
  let current_stop = 21
  let scrollY = window.scrollY

  const STATE_INIT = 0
  const STATE_IN = 1
  const STATE_OUT = 2
  const STATE_COMPLETE = 3

  const VERBOSITY = 0

  class Pattern {
    constructor(id, controller, canvas, patterns) {
      this.id = id
      this.controller = controller
      this.stop = 21
      this.rawPatterns = patterns
      this._play = true
      this.state = STATE_INIT
      this.position = 0
      this.layer = new Layer({ visible: false })
      this.mainGroup = new Group()
      addPattern(this, COLWIDTH)
      this.rect = new paper.Shape.Rectangle({
        point: [-CANVAS_WIDTH.toPrecision(10), 0],
        size: [CANVAS_WIDTH.toPrecision(10), CANVAS_HEIGHT.toPrecision(10)],
        fillColor: "#2AA55B"
      })
      this.mainGroup.addChild(this.rect)
      this.layer.addChild(this.mainGroup)
      // this.layer.addChild(this.scrollRect)
      this.mainGroup.clipped = true
      this._promises = [null, null, null, null]
      this._resolves = [null, null, null, null]
      this._promise_rejects = [null, null, null, null]
      this.lastPosition = new Decimal(0)
      this._easing = new Decimal(0)
    }

    resize() {
      this.rect.size = [
        CANVAS_WIDTH.toPrecision(10),
        CANVAS_HEIGHT.toPrecision(10)
      ]
      resizePattern(this, COLWIDTH)
      this.rect.position = new Point({
        x: new Decimal(this.rect.size.width)
          .dividedBy(-2)
          .plus(this.getDistance())
          .toPrecision(10),
        y: new Decimal(this.rect.size.height).dividedBy(2).toPrecision(10)
      })
      this.completeMovement()
    }

    pause() {
      this._play = false
    }

    play() {
      this._play = true
    }

    canPlay() {
      return this._play
    }

    show() {
      this.layer.visible = true
    }

    setEasing(v) {
      this._easing = v
    }

    easing(v) {
      if (this._easing) return new Decimal(bezierEasing(v))

      return new Decimal(linearEasing(v))
    }

    getDistance() {
      const x0 = Math.max(
        new Decimal(this.lastPosition).times(COLWIDTH),
        new Decimal(0)
      )
      const x1 = new Decimal(this.position).times(COLWIDTH)
      return x1.minus(x0)
    }

    setSteps(step, total, mStep, mTotal) {
      this.step = new Decimal(step)
      this.total_steps = new Decimal(total)
      this.meta_step = mStep ? new Decimal(mStep) : new Decimal(step)
      this.meta_steps = mTotal ? new Decimal(mTotal) : new Decimal(total)
    }

    setPosition(i) {
      this.lastPosition = new Decimal(this.position)
      this.position = new Decimal(
        Math.min(Math.max(i, 0), TOTAL_COLUMNS.times(2).toPrecision(10))
      )
      const distance = this.getDistance()
      this.setSteps(0, distance.dividedBy(this.controller.getVelocity()).ceil())
    }

    reset(pos) {
      const newPosition = pos ? new Decimal(pos) : new Decimal(0)

      this.setSteps(new Decimal(0), new Decimal(0))
      this.lastPosition = new Decimal(0)
      this.position = newPosition
      this.rect.position = new Point({
        x: new Decimal(this.rect.size.width)
          .dividedBy(-2)
          .plus(this.getDistance())
          .toPrecision(10),
        y: new Decimal(this.rect.size.height).dividedBy(2).toPrecision(10)
      })
      // this.rect.translate(new Point(this.getDistance(), 0))
      // this.scrollRect.translate(new Point(x + 0.01, 0))
    }

    getPromise() {
      return this._promises[this.state]
    }

    setPromise(promise) {
      this._promises[this.state] = promise
    }

    getResolve() {
      return this._resolves[this.state]
    }

    setResolve(resolve) {
      this._resolves[this.state] = resolve
    }

    setReject(reject) {
      this._promise_rejects[this.state] = reject
    }

    printState(from) {
      if (VERBOSITY == 0) return
      let location = ""
      if (from) location = `[${from}]`
      console.log(
        `[${this.id}]${location} state: ${this.state}`,
        this.getPromise(),
        this.getResolve()
      )
    }

    transitionOut() {
      const that = this
      this.state = STATE_OUT

      const promise = new Promise((resolve, reject) => {
        that.setResolve(resolve)
        that.setReject(resolve)
      })
      this.setPromise(promise)

      if (this.position.equals(TOTAL_COLUMNS)) this.getResolve()()

      this.setPosition(TOTAL_COLUMNS)

      return promise
    }

    transitionComplete() {
      const that = this
      this.state = STATE_COMPLETE

      const promise = new Promise((resolve, reject) => {
        that.setResolve(resolve)
        that.setReject(reject)
      })

      this.setPromise(promise)
      this.setPosition(TOTAL_COLUMNS.times(2))

      return promise
    }

    hide() {
      this.layer.visible = false
    }

    completeMovement() {
      const x1 = new Decimal(this.position).times(COLWIDTH)

      this.printState("move")
      const resolve = this.getResolve()
      this.meta_step = this.total_steps
      this.meta_steps = this.total_steps
      this.rect.position.x = x1.minus(
        new Decimal(this.rect.size.width).dividedBy(2)
      )

      if (resolve) resolve("Complete: " + this.state)
    }

    move() {
      if (!this.canPlay()) return
      const x0 = Math.max(
        new Decimal(this.lastPosition.times(COLWIDTH), new Decimal(0))
      )
      const x1 = new Decimal(this.position).times(COLWIDTH)
      const distance = x1.minus(x0)
      const rectRight = new Decimal(this.rect.position.x).plus(
        new Decimal(this.rect.size.width).dividedBy(2)
      )

      // console.log(
      //   this.meta_step,
      //   this.meta_steps,
      //   new Decimal(this.meta_step).lessThan(this.meta_steps)
      // )

      if (new Decimal(this.meta_step).lessThan(this.meta_steps)) {
        this.step = new Decimal(this.step).plus(1)
        this.meta_step = new Decimal(this.meta_step).plus(1)
        const easing = this.easing(
          new Decimal(1).dividedBy(this.meta_steps).times(this.meta_step)
        )
        let bit = 0

        if (
          new Decimal(this.meta_step).lessThan(2) &&
          this.state == STATE_INIT
        ) {
          bit = this.controller.getVelocity().times(-0.75)
        }

        const x = distance
          .times(easing)
          .minus(
            distance.times(
              this.easing(
                new Decimal(1)
                  .dividedBy(this.meta_steps)
                  .times(new Decimal(this.meta_step).minus(1))
              )
            )
          )
        const remainingX = x1.minus(rectRight).toPrecision(5)
        const finalX = x.minus(bit).toPrecision(5)
        this.rect.translate(
          new Point({ x: Math.min(finalX, remainingX), y: 0 })
        )

        if (new Decimal(this.meta_step).equals(this.meta_steps)) {
          this.completeMovement()
        }
      }
    }

    teardown() {
      this.layer.remove()
    }
  }

  class PatternController {
    constructor(canvas, patterns, velocity) {
      const that = this
      this.canvas = canvas
      this.raw_list = patterns
      this.pattern_list = []
      this.active_list = []
      this.current_index = -1
      this.locked = false
      this._velocity = COLWIDTH.times(VELOCITY_RATE)
      this.setupOnFrame()
    }

    setupOnFrame() {
      const that = this
      let fps = 30
      let lastFps = 30
      let lastDate = new Date()
      let currentDate
      let slowMode = false
      let slowCount = 0

      paper.view.onFrame = e => {
        currentDate = new Date()
        fps = 1000 / (currentDate - lastDate)
        lastDate = currentDate

        if (!slowMode) {
          this.active_list.forEach(p => {
            p.move()
          })
          paper.view.draw()

          if (fps < 25 && lastFps < 25) {
            slowCount += 1
          } else {
            slowCount = 0
          }

          if (slowCount > 10) {
            slowCount = 0
            slowMode = true
          }
        } else {
          if (fps >= 25 && lastFps >= 25) {
            slowCount += 1
          } else {
            slowCount = 0
          }

          if (slowCount > 10) {
            slowCount = 0
            slowMode = false
          }
        }

        lastFps = fps
      }
      paper.view.draw()

      setInterval(() => {
        if (slowMode) {
          this.active_list.forEach(p => {
            p.move()
          })
          paper.view.draw()
        }
      }, 33)
    }

    getCurrent() {
      const that = this
      return this.active_list.find(v => {
        return v.id == that.current_index
      })
    }

    setCurrent(i) {
      if (i >= PATTERNS.length) return false
      const point = PATTERNS[i]
      const current = new Pattern(i, this, this.canvas, point)
      this.current_index = i
      this.active_list.push(current)

      return current
    }

    getVelocity() {
      return this._velocity
    }

    setVelocity(v) {
      this._velocity = new Decimal(v)
    }

    transitionTo(i, position, delay) {
      if (this.locked) return false
      this.locked = true

      const that = this
      const oldPattern = this.getCurrent()
      oldPattern.setEasing(true)
      const oldPromise = oldPattern.transitionOut()
      let outResolve
      let inResolve
      const outPromise = new Promise((resolve, reject) => {
        outResolve = resolve
      })
      const inPromise = new Promise((resolve, reject) => {
        inResolve = resolve
      })

      // When old pattern has filled the screen, start bringing on new pattern
      oldPromise.then(function() {
        oldPattern.printState("oldpromise")
        oldPattern.setEasing(false)
        oldPattern.pause()
        that.setCurrent(i)
        const pattern = that.getCurrent()
        const promise = oldPattern.transitionComplete()
        outResolve()

        pattern.show()
        if (delay) {
          pattern.pause()
        }
        pattern.setEasing(false)
        pattern.setPosition(position)
        oldPattern.play()

        const newPromise = new Promise((resolve, reject) => {
          pattern.setResolve(resolve)
          pattern.setReject(reject)
        })

        newPromise.then(() => {
          oldPattern.setEasing(true)
        })

        // Remove old pattern after it has transitioned off screen
        promise.then(() => {
          pattern.printState("remove")
          oldPattern.hide()

          if (delay) {
            pattern.setEasing(true)
            pattern.play()
          }

          setTimeout(() => {
            oldPattern.teardown()
            that.active_list.shift()
            inResolve()
            that.locked = false
          }, 10)
        })
      })

      return [outPromise, inPromise]
    }

    load(i, position, hard) {
      this.locked = true

      const pattern = this.setCurrent(i)
      pattern.setEasing(true)
      pattern.state = STATE_IN
      const promise = new Promise((resolve, reject) => {
        pattern.setResolve(resolve)
        pattern.setReject(reject)
      })

      current_stop = position
      pattern.setPromise(promise)
      pattern.show()

      if (hard) {
        pattern.reset(position)
        pattern.getResolve()()
        this.locked = false
        return false
      } else {
        pattern.setPosition(position)
      }

      return promise
    }

    reset() {
      const that = this

      this.active_list.forEach(p => {
        const r = p.getResolve()
        if (r) r()
        p.reset(p.position)
      })
    }

    resize(...args) {
      const that = this
      clearTimeout(resizeTimer)

      CANVAS_WIDTH = getCanvasPx(canvasEl.width)
      CANVAS_HEIGHT = getCanvasPx(canvasEl.height)
      COLWIDTH = CANVAS_WIDTH.dividedBy(TOTAL_COLUMNS)

      controller.setVelocity(COLWIDTH.times(VELOCITY_RATE))

      resizeTimer = setTimeout(() => {
        if (!that.locked) {
          this.active_list.forEach(p => {
            p.resize()
          })
        }
      }, 100)
    }

    scroll(...args) {
      scrollY = args[0] > 0 ? Math.ceil(args[0] / (COLWIDTH * 4)) : 0
    }
  }

  const controller = new PatternController(canvasEl, PATTERNS, patternIndex)

  return {
    obj: controller,
    resize: (...args) => {
      controller.resize(...args)
    },
    scroll: (...args) => {
      controller.scroll(...args)
    }
  }
}
