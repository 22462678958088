import register from "../util/init"
import { isMobileSite } from "./constants"
import { carousel } from "./featureCarousel"
import { VideoPlayer } from "../../videoplayer/videoplayer"

function homePage(container) {
  // const controller = pats()
  //
  // controller.load(0, 46)
}
register(".fos-home", homePage)

const getChildNumber = node => {
  return Array.prototype.indexOf.call(node.parentNode.childNodes, node)
}

function categoryPage(container) {
  const categoryWrap = container.querySelector(".page-wrap")
  const carouselWrap = categoryWrap.querySelector(".carousel-wrap")
  let isMobile = isMobileSite()
  let carouselObj = {}
  let videoObj

  if (carouselWrap) {
    if (isMobile) {
      carouselWrap.querySelectorAll(".video-player-wrap").forEach(el => {
        const videoPlayeEl = el.querySelector(".video-player")

        if (videoPlayeEl) videoObj = new VideoPlayer(videoPlayeEl)
      })
    } else {
      const tabsWrap = carouselWrap.querySelector(".tab-wrap")
      carouselObj = carousel(carouselWrap)

      if (tabsWrap && carouselObj.flkty) {
        tabsWrap.querySelectorAll(".tab").forEach((el, i) => {
          el.addEventListener("click", () => {
            const itemEl = carouselWrap.querySelector(
              `.carousel-item[data-id="${el.getAttribute("data-id")}"]`
            )
            carouselObj.flkty.select(getChildNumber(itemEl))
          })
        })
        carouselObj.flkty.on("select", i => {
          const el = carouselObj.flkty.selectedElement

          tabsWrap.querySelectorAll(".tab.is-selected").forEach(x => {
            x.classList.remove("is-selected")
          })

          tabsWrap
            .querySelector(`.tab[data-id="${el.getAttribute("data-id")}"]`)
            .classList.add("is-selected")
        })
      }
    }
  }

  return {
    resize: (...args) => {
      isMobile = isMobileSite()
    },
    teardown: () => {
      if (carouselObj.flkty) carouselObj.flkty.destroy()
      if (videoObj) videoObj.teardown()
    }
  }
}
register(".fos-category", categoryPage)
